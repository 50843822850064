<template>
  <div class="maxHeight">
    <div class="header">
      <div class="title flex">
        <img src="../../assets/img/user.png" alt="" width="28">
        <h2>菜单管理</h2>
      </div>
    </div>
    <div class="contentBody">
      <div class="flex tableHead">
        <el-button size="small" @click="add">新增菜单</el-button>
      </div>
      <el-table
          border row-key="id" :tree-props="{children: 'children', hasChildren: true}"
          :default-sort="{prop: 'date', order: 'descending'}"
          :data="tableData"
          class="record-table"
          :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
          height="calc(100vh - 340px)">
        <el-table-column prop="text" label="名称" fixed width="150" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="类型" width="120">
          <template slot-scope="scope">
            <el-tag :type="scope.row.text === '菜单' ? 'primary' :'danger'" disable-transitions>
              {{ scope.row.text }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="path" label="地址"  show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="component" label="Vue组件"  show-overflow-tooltip>
        </el-table-column>
        <!--   <el-table-column prop="permission" label="权限" width="100" show-overflow-tooltip>
           </el-table-column>
           <el-table-column prop="order" label="排序" width="50" show-overflow-tooltip>
           </el-table-column>-->
        <el-table-column prop="createTime" label="创建时间" width="150" sortable show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.createTime | formatDay }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="modifyTime" label="修改时间" width="150" sortable show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.modifyTime | formatDay }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作"  width="90">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.row.id, scope.row)" type="text" size="small">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog :close-on-click-modal="false"
        :title="isEdit==true?'编辑':'新增'+'菜单'"
        class="flex dialogForm "
        top="0" @close="closeMenuDialog"
        :visible.sync="dialogFormVisible"
        width="400px">
      <el-form ref="formAction" size="small" :model="formAction" class="addForm" label-suffix=":" :rules="ruleInline"
               label-width="90px">
        <el-form-item label="菜单名称" prop="name">
          <el-input v-model="formAction.name" autocomplete="off" placeholder="请输入菜单名称"></el-input>
        </el-form-item>
        <el-form-item label="菜单URL" prop="path">
          <el-input v-model="formAction.path" autocomplete="off" placeholder="请输入菜单URL"></el-input>
        </el-form-item>
        <el-form-item label="组件名称" prop="component">
          <el-input v-model="formAction.component" autocomplete="off" placeholder="请输入组件名称"></el-input>
        </el-form-item>
        <el-form-item label="菜单排序">
          <el-input v-model="formAction.sort" autocomplete="off" placeholder="请输入菜单排序"></el-input>
        </el-form-item>
        <el-form-item label="上级菜单" class="permission">    <!--@check-change 节点选中状态发生变化时的回调-->
          <!--@nodeClick    节点被点击时的回调-->
          <el-tree :data="menu" accordion @check-change="handleClick" ref="treeForm" @node-click="nodeClick"
                   :default-checked-keys="stdNode"
                   show-checkbox node-key="id" :check-strictly="true" :props="defaultProps">
            <!--v-model="formAction.parentId"-->
          </el-tree>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="createMenu('formAction')">确认</el-button>
          <el-button size="small" type="primary" plain @click="dialogFormVisible = false">取消</el-button>
        </el-form-item>
      </el-form>

    </el-dialog>
  </div>

</template>

<script>
export default {
  name: "menuList",
  data() {
    return {
      tableData: [],
      menu: [],
      formAction: {
        id: '',
        name: '',
        path: '',
        url: '',
        parentId: '',
        component: '',
        //permission: '',
        sort: '',
        createTime: '',
        updateTime: '',
        // type: '',
      },
      ruleInline: {
        name: [
          {required: true, message: '菜单名称不能为空', trigger: 'blur'}
        ],
        path: [
          {required: true, message: 'URL不能为空', trigger: 'blur'},
        ],
        component: [
          {required: true, message: '组件名称不能为空', trigger: 'blur'},
        ],
      },
      dialogFormVisible: false,
      isEdit: false,
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      checkedId: null,
      stdNode: [],
    }
  },
  mounted() {
    this.getDataFromServer()
  },
  methods: {
    //获取菜单信息
    getDataFromServer() {
      this.$get("/oauth/menu").then(res => {
        this.tableData = res.data.children;
        this.menu = res.data.children;
      })
    },
    //新建菜单编辑删除
    handleEdit(id, row) {
      let b = Number(row.parentId) === 0 ? row.id : row.parentId;
      this.stdNode = [b];
      this.isEdit = true;
      //this.formAction = row ;//直接赋值无法编辑
      this.formAction.menuId = row.id;
      this.formAction.path = row.path;
      this.formAction.component = row.component;
      this.formAction.name = row.text;
      this.formAction.parentId = row.parentId;
      this.formAction.isParent = row.isParent;
      this.formAction.requireAuth = row.requireAuth;
      this.formAction.enabled = row.enabled;
      this.formAction.sort = row.order;
      //this.formAction.createTime = row.createTime;
      // this.formAction.updateTime = row.updateTime;

      this.dialogFormVisible = !this.dialogFormVisible;
    },
    add() {
      this.isEdit = false;
      this.formAction = {}
      this.dialogFormVisible = !this.dialogFormVisible;
    },
  /*  handleDelete(id) {
      this.$confirm('此操作将删除该数据，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(id)
        this.$del("/oauth/menu/" + id).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },*/
    // 部门单选
    handleClick(data, checked, node) {

      if (checked == true) {
        this.checkedId = data.id;
        if (this.isEdit == false) {
          this.formAction.parentId = data.id
        }
        this.$refs.treeForm.setCheckedKeys([data.id]);
      } else {
        if (this.checkedId == data.id) {
          this.$refs.treeForm.setCheckedKeys([data.id]);
        }
      }
    },
    //该节点所对应的对象、节点对应的 Node、节点组件本身。
    nodeClick(data, checked, node) {
      this.checkedId = data.id
      this.$refs.treeForm.setCheckedKeys([data.id]);
    },
    createMenu(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let menu = this.formAction;
          menu.sort = Number(this.formAction.sort)
          if (this.isEdit) {
            menu.parentId = (this.checkedId === null ? this.formAction.parentId : this.checkedId)
            this.$put('/oauth/menu', menu).then(() => {
              this.$message.success("修改成功")
              this.dialogFormVisible = false
              this.getDataFromServer();
            }).catch(err => {
              this.$message.error(err.response.data.message)
            })
          } else {
            this.$get('/oauth/menu', menu).then(() => {
              this.$message.success("添加成功")
              this.dialogFormVisible = false
              this.getDataFromServer();
            }).catch(err => {
              this.$message.error(err.response.data.message)
            })
          }
        }
      })
    },
    //关闭弹窗事件
    closeMenuDialog() {
      this.$refs.treeForm.setCheckedKeys([]);
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/exper/experiment";
</style>
